import Vue from "vue";
import titleMixin from "@/mixins/title";
export default Vue.extend({
    mixins: [titleMixin],
    data() {
        return {
            title: this.$t("403.title")
        };
    },
    methods: {
        returnBack() {
            this.$router.back();
        },
        returnToDashboard() {
            return this.$router.push({ name: "dashboard" });
        }
    }
});
